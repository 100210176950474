/* Tailwind for prototyping */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400&display=swap');

/* TODOs
Light Mode Colours
Skills Page
*/

/* || Custom Colours:
Teal: #17857E
Tan:  #cca87f
Blue: #062439
Purp: #062439
*/

:root{
  /* Variables */
  --custom-light-teal-colour: #7ce6de;
  --custom-teal-colour: #17857E;
  --custom-light-grey-colour: #cbd5e1;
  --custom-dark-grey-colour: #64748b;
  --custom-tan-colour: #cca87f;
  --custom-blue-colour: #334155;
  --custom-purp-colour: #bc13fe;
  --custom-dark-purp-colour: #40275D;
  --custom-dark-purp-colour-60: rgba(64, 39, 93, 0.6);
  --custom-dark-purp-colour-80: rgba(64, 39, 93, 0.8);

}


/*#region || BASICS */
body{
  background-color: #051622;
}

p,
h1,
h2,
h3,
h4,
h5{
  text-align: center;
  font-family: 'Lato', sans-serif;
}

h1{
  font-size: 3rem;
  font-weight: 300;
  line-height: 1;
  margin-bottom:1.25rem;
  color: var(--custom-light-teal-colour);
  /* color: white; */
  animation: header-neon-pulsate 2s infinite alternate;
}

h2{
  color: var(--custom-light-grey-colour);
  font-size: 1.5rem;
  line-height: 1.75rem;
}

h3{
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 0.75rem;
  color:var(--custom-light-teal-colour);
  /* color: white; */
  text-shadow:
    0 0 7px var(--custom-light-teal-colour),
    0 0 10px var(--custom-light-teal-colour),
    0 0 21px var(--custom-light-teal-colour);
}

h4{
  color: white;
}

p{
  color: var(--custom-tan-colour);
}

.neon-text-white{
  color: #fff;
  text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #cff;
}
.neon-text-teal{
  color:var(--custom-light-teal-colour);
  text-shadow:
    0 0 7px var(--custom-light-teal-colour),
    0 0 10px var(--custom-light-teal-colour),
    0 0 21px var(--custom-light-teal-colour),
    0 0 42px var(--custom-teal-colour),
    0 0 82px var(--custom-teal-colour),
    0 0 92px var(--custom-teal-colour),
    0 0 102px var(--custom-teal-colour),
    0 0 151px var(--custom-teal-colour);
}

.neon-border-purple{
  box-shadow: 0 0 .2rem white,
              0 0 .2rem white,
              0 0 2rem var(--custom-purp-colour);
}

.neon-border-teal{
  box-shadow: 0 0 .2rem white,
              0 0 .2rem var(--custom-light-teal-colour),
              0 0 2rem var(--custom-teal-colour);
}

.neon-border-white{
  box-shadow: 0 0 .2rem white,
              0 0 .2rem white,
              0 0 1rem #cff;
}

.WIP_text{
  color: rgb(239 68 68);
  font-weight: bold;
  font-size: 4.5rem;
  line-height: 1;
  margin-top: 25px;
}
/* #endregion */


/*#region || BASICS ANIMATION */
@keyframes header-neon-pulsate {
  100% {
    color: white;
    /* Larger blur radius */
    text-shadow:
    0 0 7px white,
    0 0 10px white,
    0 0 13px white,
    0 0 16px white,
    0 0 21px white,
    0 0 38px var(--custom-light-teal-colour),
    0 0 82px var(--custom-light-teal-colour),
    0 0 92px var(--custom-light-teal-colour),
    0 0 102px var(--custom-light-teal-colour),
    0 0 151px var(--custom-light-teal-colour);
  }
  0% {
    color: white;
    /* Smaller blur radius */
    text-shadow:
    0 0 7px white,
    0 0 10px white,
    0 0 21px white,
    0 0 38px var(--custom-light-teal-colour),
    0 0 82px var(--custom-light-teal-colour),
    0 0 92px var(--custom-light-teal-colour),
    0 0 102px var(--custom-light-teal-colour),
    0 0 151px var(--custom-light-teal-colour);
  }
}
/*#endregion  */


/*#region || GENERAL */
.full-w-flex-col{
  display:flex;
  flex-direction: column;
  width: 100%;
}

.full-w-center{
  border: 5px dotted yellow;
}

.footer-img{
  margin: auto;
  width: 5rem;
  height: 5rem;
}
/* #endregion */


/*#region || DROPDOWN SELECT */
.dropbtn {
  background-color: var(--custom-dark-purp-colour-80);
  border-radius: 0.3rem;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown:hover{
  background-color: var(--custom-teal-colour);
}

.dropdown {
  border-radius: 0.4rem;
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--custom-dark-purp-colour-80);
  border-radius: 0.3rem;
  min-width: 160px;
  left: 50%;
  transform: translateX(-50%);
}

.dropdown-content button {
  position: relative;
  margin-left: auto;
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  width: 100%;
  display: block;
  border-radius: 0;
}

.dropdown-content button:hover {
  background-color: var(--custom-blue-colour);
}

.dropdown-content button:focus{
  background-color: var(--custom-blue-colour);
}
.show-dropdown{
  display: block;
}
/* #endregion */


/*#region || BUTTON */
button{
  background-color: transparent;
  padding: 0.25rem;
  border-radius: 0.25rem;
  font-weight: bolder;
  font-size: large;
  padding-bottom: 5px;
}

.btn-center-container{
  text-align: center;
}
/* #endregion */


/*#region || NAVBAR */
/* Default Nav Bar */
nav{
  display:flex;
  margin-top: 0.75rem;
  margin-bottom: 1.25rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  padding-bottom: 0.25rem;
  border-bottom: 4px double var(--custom-light-grey-colour);
  box-shadow: 0 0.4rem 0.5rem var(--custom-teal-colour);
}

.default-nav{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.navbox{
  width: 6rem;
  padding: 0.5rem;
  margin-right: -1px;
  display:inline-flex;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: white;
  border: 2px solid ;
  place-content: center;
  flex-grow: 1;
  transition: linear 300ms;
}

.navbox:hover{
  color: white;
  background-color: var(--custom-blue-colour);
}

.active_navbox{
  background-color: var(--custom-dark-grey-colour);
}

.nav-right{
  margin-left: auto;
}

/* Nav Popout */
.mobile-nav{
  display: none;
}

.nav-btn{
  display: none;
  font-size: xx-large;
}

.show-dropdown-nav{
  display: flex;
}

.dropdown-nav{
  width: 50%;
  flex-direction: column;
  /* align-items: flex-end; */
}

.dropdown-nav .navbox{
  /* display: flex; */
  margin-top: 0.2rem;
  width: 10rem;
  font-size: x-large;
}



/* #endregion */


/*#region || HOMEPAGE */
.hp-card-container{
  position: relative;
  height: 30rem;
}

.hp-card-group{
  position: absolute;
  width: 25rem;
  height: 30rem;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}

.hp-card{
  display: inline-block;
  position: absolute;
  width: 15rem;
  height: 20rem;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  border-radius: 0.3rem;
  /* Beveleld Edge Attempt - breaks border glow */
  /* https://css-generators.com/custom-corners/ */
  /* clip-path: polygon(0 20.00px,20.00px 0,calc(100% - 20.00px) 0,100% 20.00px,100% calc(100% - 20.00px),calc(100% - 20.00px) 100%,20.00px 100%,0 calc(100% - 20.00px)); */
  background-color: var(--custom-dark-purp-colour-60);
  transition:cubic-bezier(0.165, 0.84, 0.44, 1) 300ms ;
}

.hp-card h4{
  font-size: x-large;
}

/* Each individual nav card */
.hp-card:nth-child(1){
  --translateX: -3rem;
  --rotate: -10deg;
  transform: rotate(var(--rotate)) translateX(var(--translateX));
}
.hp-card:nth-child(2){
  --translateX: 0;
  --rotate: 0;
  transform: rotate(var(--rotate)) translateX(var(--translateX));
}
.hp-card:nth-child(3){
  --translateX: 3rem;
  --rotate: 10deg;
  transform: rotate(var(--rotate)) translateX(var(--translateX));
}

.hp-card:hover{
  box-shadow: 0 0 .2rem white,
    0 0 .2rem white,
    0 0 2rem var(--custom-purp-colour),
    0 0 0.8rem var(--custom-purp-colour),
    0 0 2.8rem var(--custom-purp-colour),
    inset 0 0 1.3rem var(--custom-purp-colour);

  transform: rotate(var(--rotate)) 
    translateX(var(--translateX)) 
    translateY(-3rem)
    scale(1.05);
}

/* NO HOVER */
.mobile-hp-card-container{
  position: relative;
  height: 35rem;
}

/* Each individual nav card */
.mobile-hp-card-group > .hp-card:nth-child(1){
  --translateY: -5rem;
  --rotate: 0;
  transform: rotate(var(--rotate)) translateY(var(--translateY));
}
.mobile-hp-card-group > .hp-card:nth-child(2){
  --translateY: 0;
  --rotate: 0;
  transform: rotate(var(--rotate)) translateY(var(--translateY));
}
.mobile-hp-card-group > .hp-card:nth-child(3){
  --translateY: 5rem;
  --rotate: 0;
  transform: rotate(var(--rotate)) translateY(var(--translateY));
}
/*#endregion */


/*#region || PROJECTS PAGE */
.project-link{
  width: 66.666667%;
  margin: 1.25rem auto;
}
.project{
  display: flex;
  flex-direction: row;
  background-color: var(--custom-dark-purp-colour);
  border-radius: 0.5rem;
  height: 24rem;
  box-shadow: 0 0 .2rem white,
              0 0 .2rem white,
              0 0 2rem var(--custom-purp-colour);
  transition-duration: 500ms;
}


.project[imageSide="right"]{
  flex-direction: row-reverse;
}

.project:hover{
  border: 5px solid white;
  box-shadow: 0 0 .2rem white,
              0 0 .2rem white,
              0 0 2rem var(--custom-purp-colour),
              0 0 2.2rem var(--custom-purp-colour),
              inset 0 0 1.2rem var(--custom-purp-colour);
}
.project:hover img{
 border-radius: 0.1rem;
}

.project_txt{
  padding: 1.25rem;
  width: 100%;
}

/* .project h1{
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 0.75rem;
} */

.project p{
  color: var(--custom-tan-colour);
  text-align: left;
}

.project_img{
  display:inline-flex;
}

.project_img img{
  object-fit: cover;
  border-radius: 0.5rem;
  transition-duration: 500ms;
}

/* MORE IN @media */

/* #endregion */


/*#region || GALLERY */
.gallery_container{
  display:flex;
  flex-wrap: wrap;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding-top: 20px;
  margin-top: 20px;
  border-radius: 1rem;
}

.gallery_tile{
  min-width: 8rem;
  min-height: 8rem;
  max-width: 8rem;
  max-height: 8rem;
  flex: 0 1 auto;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  border-top-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.gallery_tile:hover{
  max-height: 15rem;
  max-width: 10rem;
}

.gallery_tile img{
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-top-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

/* MORE IN @media */

/* #endregion */


/*#region || PHOTO CARDS */
.photo-cards-container{
  position: relative;
  padding-top: 5px;
  width: 50%;
  height: 30rem;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 26rem; */
}

.photo-card{
  position: absolute;
  width: 26rem;
  height: 26rem;
  top: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1), 300ms;
  z-index: 1;
}

.photo-card[data-status="right"]{
  transform: translateX(60%) scale(0);
}

.photo-card[data-status="left"]{
  transform: translateX(-60%) scale(0);
}

.photo-card[data-status="unknown"]{
  transform: scale(0);
}

.photo-card img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.photo-card img:hover{
  object-fit: contain;
}

/* BUTTONS */
.photo-cards-btn-container{
  width: 70%;
  margin: 26.75rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.photo-cards-btn-container button{
  width: 50px;
}
/* #endregion */


/*#region || ABOUT */
.about-img{
  margin:auto;
  width: 15rem;
  height: 15rem;
}

/*#endregion  */


/*#region || PAGE NOT FOUND */
.page-not-found h1{
  margin-bottom: 1.25rem;
  font-weight: bold;
}

.page-not-found h2{
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
  font-size: 1.25rem;
}
/* #endregion */


/* #region || FOOTER */
footer{
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1.25rem;
}

.cc{
  display:inline-flex;
  color: var(--custom-tan-colour);
  justify-content: center;
  width: 100%;
}

.cc-link{
  display: inline-flex;
  justify-content: center;
}

.cc img{
  height: 22px;
  margin-left: 0.5rem;
}
/* #endregion */


/*#region || UNITY PAGE */
.unity-box{
  width: 800px;
  height: 600px;
  margin-left: auto;
  margin-right: auto;
}
/* #endregion */


/*#region || SKILLS PAGE */
.collapsible-article{
  position: relative;
  max-width: 50rem;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;
  border-radius: 1rem;
  padding-bottom: 5rem;
  padding-top: 0.5rem;
}

.collapsible-content{
  display: grid;
  grid-template-columns: auto auto auto;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-duration: 1000ms;
  max-height: 230px;
  padding-bottom: 3rem;
  overflow: hidden;
}

.collapsible-content > div{
  margin: auto;
  margin-top: 3rem;
  width: 10rem;
  height: 10rem;
}

.collapsible-article button{
  position: absolute;
  bottom: 1rem;
  width: 90%;
  border-top: var(--custom-light-grey-colour) solid;
  left: 50%;
  transform: translateX(-50%);
  display: block;
}

.collapsible-expand{
  max-height: 900px;
}

/*#endregion */



/*#region || VERY SMALL SCREEN */
@media (max-width: 450px) {

  /* ||^ GALLERY PAGE */
  .gallery_tile:hover{
    max-height: 15rem;
    max-width: 8rem;
  }

  .gallery_tile{
    min-width: fit-content;
    max-width: fit-content;
    margin-bottom: 1rem;
    /* width: 20rem; */
  }

   /* ||^ NAVBAR */
   nav{
    border-bottom: 4px solid var(--custom-light-grey-colour);
    box-shadow: none;
    flex-direction: column;
    align-items: flex-end;
  }

  .mobile-nav{
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .default-nav{
    display: none;
  }

  .dropdown-nav{
    width: 50%;
    display: none;
    flex-direction: column;
    align-items: flex-start;
  }

  .show-dropdown-nav{
    display: flex;
  }

  .nav-btn{
    display:flex;
    margin-left: auto;
  }


}
/* #endregion */


/*#region || SMALL SCREEN */
@media (max-width: 768px) {
  
  /* ||^ PROJECT PAGE */
  .project_img{
    width: 0px;
  }

  /* ||^ GALLERY PAGE */
  .gallery_tile{
    margin: 0.5rem;
  }

  /* ||^ GALLERY PAGE CARD VIEW */
  .photo-card{
    max-width: 20rem;
    max-height: 20rem;
    min-width: 10rem;
    min-height: 10rem;
    width: 80%;
    height: 80%;
    
    /* margin-left: auto; */
    /* margin-right: auto; */
  }
  .photo-cards-container{
    height: 70vw;
    width: 70vw;
  }
  .photo-cards-btn-container{
    margin: 90% auto;
  }

  /* ||^BASICS */
  .WIP_text{
    font-size: 3rem;
  }

  /* ||^ HOMEPAGE */
  .hp-card-group{
    width: 95%;
    margin: none;
  }

  /* ||^ COLLAPSIBLE */
  .collapsible-content{
    grid-template-columns: auto auto;
  }

  /* .collapsible-expand{
    height: 680px;
  } */

}
/* #endregion */


/*#region || MEDIUM SCREEN */
@media (min-width: 768px) {
  
  /* ||^ PROJECT PAGE */

  .project_img{
    width: 50%;
  }

  /* ||^ GALLERY PAGE */
  .gallery_tile{
    margin: 0.75rem;
  }

}
/* #endregion */


/*#region || LARGE SCREEN */
@media (min-width: 1280px) {
  
  /* ||^ PROJECT PAGE */
  .project_img{
    width: 100%;
  }

  /* ||^ GALLERY PAGE */
  .gallery_tile{
    margin: 1.25rem;
  }

}
/* #endregion */


/* 
CSS NAVIGATION KEY

||  ->  New Section
||^ -> New Section in @media

*/
